
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Added per recommendation in:
// https://github.com/rails/webpacker/tree/v5.1.1#usage


import * as Routes from '../routes.js.erb';
window.Routes = Routes;

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Rails from 'rails-ujs';
Rails.start();

import 'style_guide/components/form/show_password_toggler';

import "utilities/print";
import "utilities/require-fields";
import "utilities/phone_number_mask";
import { showSuccessMessage, showSuccessButton, showSuccessTitle, showErrorMessage } from "utilities/alert";

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';

import 'jquery-visible/jquery.visible.min.js';
import 'jquery-touchswipe/jquery.touchSwipe.min.js';

import smoothScroll from 'smoothscroll-polyfill';

import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

import "checkouts/custom_start_date";
import "checkouts/plan_selection";
import "checkouts/metadata_update";
import "checkouts/credit_card_payment";
import "checkouts/terms_checkbox_toggle";
import "checkouts/payment_button";
import "checkouts/non_cc_payment";
import "checkouts/braintree";
import "checkouts/ach_payment";
import "checkouts/paypal_payment";
import "checkouts/capture_email_modal";
import "checkouts/open_assisted_checkout_modal";

import 'shared/add_named_person';
import 'shared/handle_photo_uploads';
import 'shared/components/tooltip';
import 'shared/components/popover';
import "shared/components/spreadings_modal";
import "shared/components/notification";

import "dashboard/bpf_video_player";
import "dashboard/meet_my_tree";
import "dashboard/async_loader";
import "dashboard/browser_support_disclaimer";
import "dashboard/cancel_button";
import "dashboard/credit_card";
import "dashboard/modal_carousel";
import "dashboard/nav";
import "dashboard/payments";
import "dashboard/skip_link";
import "dashboard/add_readings_button";
import { showDashboardSuccess, showDashboardError, showReferralPrompt } from "dashboard/alert";

import "referrals/form";
import "referrals/share-on-facebook";
import "referrals/share-options";
import "referrals/copy-to-share";

import { setupForestMap } from "ecommerce/maps/forest_map"
import { setupSectionsMap } from "ecommerce/maps/forest_sections_map"
import { setupTreesMap } from "ecommerce/maps/all_trees_map"
import { setupAllForestsMap } from "ecommerce/maps/all_forests_map"
import { getDistanceToForest } from "ecommerce/maps/all_forests_map"
import { removeLayer, addFavoriteStyle, removeFavoriteStyle } from "ecommerce/maps/map_utils"
import { setupTreeLocationMap } from "ecommerce/maps/tree_location_map"
import { treesDesktopMapListeners, mapLegendListeners } from "ecommerce/maps/trees_desktop_map_listeners"
import { treesMobileMapListeners } from "ecommerce/maps/trees_mobile_map_listeners"
import { highlightMobileTreeCardsOnSwipe } from "ecommerce/maps/trees_mobile_map_listeners"
import "ecommerce/nav";
import "ecommerce/dropdown_menu";
import "ecommerce/favorite"
import "ecommerce/nav_toggle"
import "ecommerce/show_more_toggle";
import "ecommerce/slides";
import "ecommerce/welcome_videos";
import "ecommerce/saved_trees";
import "ecommerce/trees/mobile/filters";
import "ecommerce/trees/filters";
import "ecommerce/welcome/all_forests";
import "ecommerce/welcome/about_this_forest_carousel";
import "ecommerce/website_traffic/nav";
import "ecommerce/website_traffic/mobile_nav";
import { progressiveLoadPhoto } from "ecommerce/progressive_image_loading";
import { footerHeight } from "ecommerce/trees/footer";
import "ecommerce/horizontal_scrolling_carousel";
import "ecommerce/search_by_arbor_tag";
import { showNotification } from "ecommerce/notifications";
import 'ecommerce/promo_banner';
import 'ecommerce/collections';

global.Rails = Rails;
global.jQuery = global.$ = $
global.BPF = {
  DONE_TYPING_INTERVAL: 200,
  Dashboard: {
    showSuccessMessage: showDashboardSuccess,
    showErrorMessage: showDashboardError,
    showReferralPrompt: showReferralPrompt,
  },
  Ecommerce: {
    showSuccessMessage,
    showErrorMessage,
    showSuccessButton,
    showSuccessTitle,
    setupForestMap,
    setupTreesMap,
    setupSectionsMap,
    setupTreeLocationMap,
    setupAllForestsMap,
    getDistanceToForest,
    removeLayer,
    treesDesktopMapListeners,
    mapLegendListeners,
    treesMobileMapListeners,
    highlightMobileTreeCardsOnSwipe,
    progressiveLoadPhoto,
    addFavoriteStyle,
    removeFavoriteStyle,
    footerHeight,
    showNotification,
  },
}
if (!global.BPF.Intercom) global.BPF.Intercom = {};

smoothScroll.polyfill();

SwiperCore.use([Navigation, Pagination]);
const swiperDefaultOpts = {
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
};

$(function () {
  document.querySelectorAll('[data-swiper]').forEach(container => {
    const swiperRawData = container.dataset['swiper'];
    const swiperData = JSON.parse(swiperRawData);

    let swiperOpts = { ...swiperDefaultOpts, ...swiperData };
    swiperOpts['pagination'] = {...swiperDefaultOpts.pagination, ...swiperData['pagination']};
    swiperOpts['navigation'] = {...swiperDefaultOpts.navigation, ...swiperData['navigation']};
    swiperOpts['scrollbar'] = {...swiperDefaultOpts.scrollbar, ...swiperData['scrollbar']};

    let klassesSelector = '';
    container.classList.forEach(klass => klassesSelector += "." + klass);
    new Swiper(klassesSelector, swiperOpts);
  });

  // Fix GA injected via GTM conflicting with remote links
  $("a[data-remote='true'], a[data-method='delete'], a[data-method='post'], a[data-method='put'], a[data-method='patch']").click(function (e) {
    e.preventDefault();
  })
});

