export function showSuccessMessage (message, title = null, cta_link = null) {
  var modal = $("#successModal");
  modal.find(".js-message").html(message);
  modal.modal({});
  modal.find('.btn').focus();

  if(title) {
    modal.find(".js-success-title").html(title);
  }

  if(cta_link) {
    modal.find('.js-success-btn').removeAttr('data-dismiss').attr('href', cta_link);
  }
}

export function showErrorMessage (message) {
  var modal = $("#errorModal");
  modal.find(".js-message").html(message);
  modal.modal({});
  modal.find('.btn').focus();
}

export function showSuccessButton(text) {
  var modal = $("#successModal");
  modal.find(".js-success-btn").text(text);
  modal.modal({});
}

export function showSuccessTitle (title) {
  var modal = $("#successModal");
  modal.find(".js-success-title").html(title);
  modal.modal({});
  modal.find('.btn').focus();
}